import * as React from "react"
import type { HeadFC } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import PageHead from "../components/page-head"
import Projects from "../components/projects"
import Contact from "../components/contact"
import SectionHeadline from "../components/section-headline"
import { FadeInComponent } from "../components/animate"

import "../styles/globals.scss"
import "../styles/expertise.scss"

const ExpertisePage = () => {
  return (
    <Layout className="expertise">
      <div className="section hero">
        <StaticImage
          src="../images/union.png"
          loading="eager"
          alt="Union"
          className="union" />
        <h1>
          All things, in line.
        </h1>
        <p>
          Digital&nbsp;product&nbsp;strategy&nbsp;&mdash; Feasibility&nbsp;study&nbsp;&&nbsp;Design&nbsp;&mdash; Team&nbsp;building&nbsp;&mdash; Technical&nbsp;architecture&nbsp;&&nbsp;Development
        </p>
        <StaticImage
          src="../images/expertise.png"
          loading="eager"
          alt="Expertise"
          className="centered-image" />
      </div>
      

      <div className="section strategy">
        <div className="flex">
          <div className="flex-cell">
            <SectionHeadline text="What we do" />
          </div>
          <div className="flex-cell left-padded">
            <div className="strategy-item">
              <FadeInComponent>
                <h3>Digital product strategy</h3>
                <p>
                  Make products that solve real problems and measure their success in business results. 
                </p>
                <ul className="emphasized">
                  <li>Product and technology strategy</li>
                  <li>Interim product ownership</li>
                </ul>
              </FadeInComponent>
            </div>
            <div className="strategy-item">
              <FadeInComponent delay={100}>
                <h3>Team Building</h3>
                <p>
                  Build well-functioning teams that work together efficiently right from the get go.
                </p>
                <ul className="emphasized">
                  <li>Training on the best practices of product development</li>
                  <li>Coaching on agile methodologies, prioritization and resourcing</li>
                </ul>
              </FadeInComponent>
            </div>
            <div className="strategy-item">
              <FadeInComponent delay={200}>
                <h3>Feasibility study & design</h3>
                <p>
                  Recognize and solve the right challenge &mdash; not the evident one.  
                </p>
                <ul className="emphasized">
                  <li>Design work from user interviews to a finalized product</li>
                  <li>Feasibility studies and documentation e.g. due diligence material</li>
                </ul>
              </FadeInComponent>
            </div>
            <div className="strategy-item">
              <FadeInComponent delay={300}>
                <h3>Technical architecture & development</h3>
                <p>
                  Reliable, malleable systems are built on pragmatic tech.
                </p>
                <ul className="emphasized">
                  <li>Setting up a secure and resilient organization to support product development</li>
                  <li>Digital product development, from the ground up</li>
                </ul>
              </FadeInComponent>
            </div>
          </div>
        </div>
      </div>

      <Projects />

      <div className="section footer">
        <h2>Let's talk</h2>
        <Contact />
      </div>
    </Layout>
  )
}

export default ExpertisePage

export const Head: HeadFC = () => <PageHead />
