import * as React from "react"
import { Link } from "gatsby"
import classNames from "classnames"

import { StaticImage } from "gatsby-plugin-image"

import "./projects.scss"

const Projects = () => (
  <div className="section projects">
    <div className="flex">
      <div className="flex-cell right-padded">
        <a href="/hederadx">
          <StaticImage
            src="../images/projects.png"
            loading="eager"
            alt="Silver Align"
          />
        </a>
      </div>
      <div className="flex-cell description">
        <p>
          <Link to="/hederadx">
            Project story
          </Link>
        </p>
        <Link to="/hederadx">
          <h3>Better therapy options for cancer patients with Hedera Dx</h3>
          <p>In September 2021, Align joined Hedera Dx's mission to make liquid biopsies globally accessible. We became the core of Hedera Dx's interim product development team.</p>
        </Link>
      </div>
    </div>
  </div>  
)

export default Projects
